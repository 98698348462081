.attendance[data-v-0ead4426] {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}
.type[data-v-0ead4426] {
  display: inline-block;
  margin-right: 16px;
}
.toolbar[data-v-0ead4426] {
  padding: 8px 0;
}
.refresh[data-v-0ead4426] {
  float: right;
}
